import React from 'react';
import Carousel from 'react-multi-carousel';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const sanitizer = DOMPurify.sanitize;
const TextAsistensi = '<span class="text-pink-500 font-bold">asistensi</span>';
const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const banner1 = `${serverUrlImages}/PH/home/online-doctor-philippines-r.jpg`;
const banner2 = `${serverUrlImages}/PH/home/in-clinic-and-at-home.jpg`;
const banner3 = `${serverUrlImages}/PH/home/insurance-coverage.jpg`;
const banner4 = `${serverUrlImages}/PH/home/ambulance-transfers-2.jpg`;
const banner5 = `${serverUrlImages}/PH/home/laboratory-tests.jpg`;
const banner6 = `${serverUrlImages}/PH/home/medication-pickup.jpg`;

const responsive = {
	xl: {
		breakpoint: { max: 5000, min: 1920 },
		items: 3
	},
	lg: {
		breakpoint: { max: 1919, min: 1280 },
		items: 3
	},
	md: {
		breakpoint: { max: 1279, min: 960 },
		items: 2
	},
	sm: {
		breakpoint: { max: 959, min: 600 },
		items: 2
	},
	xs: {
		breakpoint: { max: 599, min: 0 },
		items: 1
	}
};

const ServicesCarousel = () => {
	const services = [
        {
            img: {
                src: banner1,
                alt: 'Unlimited telemedicine',
                name: 'medico-cabecera-virtual-mexico.jpg'
            },
            title: 'Unlimited telemedicine',
            subtitle: `<span>Our general doctors are available on-demand, 24 hours, via telephone or videocall on our ${TextAsistensi} App - available in iOS and Android.</span>`
        },
        {
            img: {
                src: banner2,
                alt: 'Unlimited home care consultations',
                name: 'lectura-examenes-medicos.png'
            },
            title: 'In-clinic and at-home primary care consultations',
            subtitle: (`
                <span>
                Whenever required, our doctors and paramedics are available to determine our members' medical needs in person as well.
                </span>
            `)
        },
        {
            img: {
                src: banner3,
                alt: 'Insurance coverage for hospitalization for medical emergencies caused by accidents and illnesses',
                name: 'servicios-ambulancias.jpg'
            },
            title: 'Insurance coverage for hospitalization for medical emergencies caused by accidents and illnesses',
            subtitle: (`
                <span>
                With access to the network of private hospitals and healthcare facilities affiliated with Maxicare. 
                Includes medical expenses coverage up to PHP 300,000.00, per year, which is enough to cover over 90% of the most common emergencies.
                </span>
                `)
        },
		{
            img: {
                src: banner4,
                alt: 'Ambulance transfers',
                name: 'medico-cabecera-virtual-venezuela.jpg'
            },
            title: 'Ambulance transfers',
            subtitle: (`
                <span>
                In the event of a medical emergency, our doctors will coordinate transfers to the hospital in ambulances equipped with advanced life support.
                </span>
                `)
        },
        {
            img: {
                src: banner5,
                alt: 'Laboratory tests',
                name: 'img-laboratorio.png'
            },
            title: 'Laboratory tests',
            subtitle: `
                <span>
                We offer 2 laboratory tests per year, as long as they are prescribed by our doctors and are included in the plan. Click <a href="faq/?q=18" class="cursor-pointer font-poppins font-bold" target="_blank"> here</a>.
                </span>
                `
        },
        {
            img: {
                src: banner6,
                alt: 'Medication pickup and delivery',
                name: 'servicios-ambulancias.jpg'
            },
            title: 'Medication pickup and delivery',
            subtitle: `<span>Includes the pick up or delivery of medication, as long as it is prescribed by our doctors and is included in the plan.</span>`
        }
	];

	return (
		<div className="pb-0 md:pb-20 container relative">
			<style global jsx>
				{`
					.transform-scale:hover .scale-children {
						transform: scale(1) !important;
						animation-name: fadeIn !important;
						animation-fill-mode: both !important;
						-webkit-animation-duration: 1s !important;
						animation-duration: 1s !important;
						-webkit-animation-fill-mode: both !important;
					}
					.min-size-arrow {
						min-width: 24px;
						min-height: 24px;
					}
					.react-multiple-carousel__arrow {
						z-index: 10 !important;
					}
				`}
			</style>
			<h2 className="jsx-3604727714 text-purple-500 text-center font-semibold text-2xl hidden md:block md:text-4xl leading-snug mb-4">
			Our services</h2>
			<h2 className="text-purple-500 text-center font-semibold text-2xl md:hidden md:text-4xl leading-snug mb-4">
			Our services
			</h2>
			<Carousel className="md:hidden"
				swipeable={true}
				draggable={true}
				responsive={responsive}
				arrows={true}
				partialVisible={false}
				customRightArrow={
					<KeyboardArrowRightIcon
						className="react-multiple-carousel__arrow min-size-arrow text-pink-500 bg-white"
						style={{ right: '-8px' }}
					/>
				}
				customLeftArrow={
					<KeyboardArrowLeftIcon
						className="react-multiple-carousel__arrow min-size-arrow text-pink-500 bg-white"
						style={{ left: '-8px' }}
					/>
				}
			>
				{services.map((benefit, i) => (
					<div
						key={i}
						className="my-4 mx-3 relative transition duration-300 ease-in-out min-h-93 rounded"
					>
						<img
							src={benefit.img?.src}
							className="w-full h-48 object-cover rounded-xl"
							name={benefit?.img?.name}
							alt={benefit?.img?.alt}
							style={{objectFit:"cover", objectPosition:"center"}}
						/>
						<p className="h-18 text-lg text-center font-bold leading-snug text-black mt-4">
							{benefit.title}
						</p>
						<p className="text-base text-center text-gray-900 leading-relaxed mt-4" dangerouslySetInnerHTML={{
							__html: sanitizer(benefit.subtitle)
						}}></p>
					</div>
				))}
			</Carousel>

			<div className="hidden md:grid grid-cols-3 gap-6">
				{services.map((benefit, i) => (
					<div
						key={i}
						className="my-4 relative transition duration-300 ease-in-out min-h-95 rounded"
					>
						<img
							src={benefit.img?.src}
							className="w-full h-49 rounded-xl"
							name={benefit?.img?.name}
							alt={benefit?.img?.alt}
							style={{objectFit:"cover", objectPosition:"center"}}
						/>
						<p className="h-14  text-lg text-center font-bold leading-snug text-black mt-4">
							{benefit.title}
						</p>
						<p className="text-base text-center text-gray-900 leading-relaxed mt-4" dangerouslySetInnerHTML={{
							__html: sanitizer(benefit.subtitle)
						}}></p>
					</div>
				))}
			</div>

		</div>
	);
};

export default ServicesCarousel;
